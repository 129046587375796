<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.TakeInventoryStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="展示仓库">
              <a-select
                option-label-prop="tt"
                show-search
                allow-clear
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="queryParam.exhibition_warehouse_id"
                placeholder="展示仓库"
                :default-active-first-option="false"
                @search="handleExhibitionWarehouseSearch"
                @change="handleExhibitionWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_exhibition_warehouse" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>-->

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRead(record)">详情</a>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { take_inventory_list } from '@/api/take_inventory'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      dataSource_exhibition_warehouse: [],
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '展示仓库',
          dataIndex: 'exhibition_warehouse_name'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.TakeInventoryStatus[text]
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作人',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'create_by'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return take_inventory_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    handleRead (record) {
      console.log(record)
      this.$router.push({ path: '/inventory/read', query: { id: record.id } })
    },
    handleRefund (record) {
      console.log(record)
    },
    handleExhibitionWarehouseSearch (value) {
      exhibition_warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_exhibition_warehouse = !value ? [] : result
      })
    },
    handleExhibitionWarehouseChange (value) {
      this.queryParam.exhibition_warehouse_id = value
    }
  }
}
</script>
